.mat-mdc-table {
    display: table;
    width: 100%;
    .mat-mdc-row {
        &:nth-child(even):not(.detail-row) {
            background-color: #F4F6F7;
        }
    }
    .mat-mdc-cell {
        vertical-align: middle;
    }
}