mat-table.mat-mdc-table {
  display: table;
}

.mat-mdc-cell {
  vertical-align: middle;
}
mat-table.mat-mdc-table {
  tr.mat-mdc-header-row {
    background: $table-background;
    height: auto;
    .mat-mdc-header-cell {
      color: $text-black;
      font-weight: 700;
      padding: 12px 24px 10px 24px;
      font-size: 14px;
      border: 1px solid $table-border;
      vertical-align: middle;
    }
  }
  tr.mat-mdc-row .mat-mdc-cell {
    border: 1px solid $table-border;
    padding: 24px;
    color: $text-black;
    a {
      color: $text-black;
      font-weight: 700;
    }
  }
}
