  // add border-radius for all buttons
  .mat-mdc-button-base {
      &.mat-mdc-raised-button,
      &.mat-mdc-stroked-button,
      &.mat-mdc-button-toggle-group,
      &.mat-mdc-flat-button,
      &.mat-mdc-unelevated-button,
      &.mat-mdc-outlined-button {
          border-radius: 10rem;

          .mat-mdc-button-ripple {
              border-radius: 10rem;
          }
      }
      mat-icon {
        vertical-align: middle;
      }
  }