/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "colors";
@import "assets/css/tables";
@import "theme";

@font-face {
  font-family: 'Fontawesome';
  src: url('https://daks2k3a4ib2z.cloudfront.net/5655bec48778b61f0bd27e33/5655d2f9fc6324210b734265_FontAwesome.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'Roboto', sans-serif;
}

.page-content > * > .container {
  background: #fff;
  margin: 3rem auto;
  padding: 1rem;
  @include mat.elevation(2);
  border-radius: 4px;
}

.content-title-icon {
  font-family: 'Fontawesome', serif;
  margin-right: 10px;
  display: inline-block;
}

.mat-mdc-snack-bar-container {
  color: #FFF;
  &.success {
    background: mat.get-color-from-palette($primary-palette, 500);
  }
  &.error {
    background: mat.get-color-from-palette(mat.$red-palette, 500);
  }
}

.error-text,
.icon-danger,
.error-label,
.password-validation-error {
  color: mat.get-color-from-palette(mat.$red-palette, 500);
}
.success-label,
.icon-success,
.success-label,
.text-success,
.password-validation-success {
  color: mat.get-color-from-palette(mat.$green-palette, 800);
}

.field-info {
  color: #555555;
}

.icon-middle,
.icon-success,
.icon-danger,
.icon-info {
  font-size: 20px;
}

.password-validation-error,
.password-validation-success {
  display: flex;
  justify-content: flex-start;
}

.dialog-title.mat-mdc-dialog-title {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  &::before {
    display: none !important;
  }
  button {
      padding: 0;
  }
}

.date-range {
  .mat-mdc-form-field-wrapper {
    width: 100%;
  }
  .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
    width: auto !important;
    padding-bottom: 0;
  }
}

.mat-mdc-snack-bar-action {
  color: white;
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

mat-table.mat-mdc-table {
  display: table;
}

.mat-mdc-cell {
  vertical-align: middle;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.mat-mdc-card {
  padding: 1rem;
  > * {
    width: 100%;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #f5f5f5 inset;
}

* {
  letter-spacing: 0 !important;
  -webkit-font-smoothing: auto !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
}
.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #FFF !important;
}